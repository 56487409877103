<template>
  <Defaultlayout :userprofile="userprofile">
    <PageSlider></PageSlider>
    <section class="about-us">
      <div class="container">
        <h1 class="page-heading ">Feedback</h1>

        <div class="description">
          <!-- <p>India Bazaar is proud to be “The Peoples’ Store”. Therefore we encourage you to contact us for any and every matter you may wanna discuss or bring to light in order to better your store.</p> -->

          <ul class="mail-listing list-unstyled">
            <li>
              <p>Ghar Bazaar is here to serve you and provide you with the most convenient online grocery shopping experience you have ever had.</p>
              <!-- <a href="mailto:Feedback@IndiaBazaar.us">Feedback@IndiaBazaar.us</a> -->
            </li>
            <li>
              <p>We Therefore we encourage you to contact us for any and every matter you may want to discuss or bring to light in order to better our service.</p>
              <!-- <a href="mailto:Support@IndiaBazaar.us">Support@IndiaBazaar.us</a> -->
            </li>
            <li>
              <p>For all questions, concerns and suggestions please email at</p>
              
            </li>
            <li>
              <a href="mailto:support@gharbazaar.us">support@gharbazaar.us</a>
              </li>
          </ul>
        </div>

      </div>
    </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import PageSlider from "../pages/PageSlider";
export default {
  name: "Feedback",
  components: {
    Defaultlayout,
    PageSlider,
  },
  data() {
    return {
      loading: true,
      userprofile: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
  },
};
</script>
